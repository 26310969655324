<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席北京基金小镇&华鑫证券联合分享会
              </p>
              <div class="fst-italic mb-2">2024年1月12日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >华鑫证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/70/01-基金小镇.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技受华鑫证券邀请，出席北京基金小镇联合华鑫证券共同举办的“私募证券基金备案案例及合规要点解析”分享会，与管理人、资方共同探讨了私募行业备案现状、合规要点、交易赋能以及未来合作机会。
                  </p>
                  <p>
                    北京基金小镇以“创新、协调、绿色、开放、共享”新发展理念为引领，打造国家级基金业专业服务平台，探索形成“1+3+N”专业服务体系，支持基金业安全发展，更好服务实体经济，推动科技创新及现代化产业体系建设，助力首都高质量发展。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/70/02-华泰.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近年来，华鑫证券坚持“金融科技引领业务发展”的差异化发展道路，已逐步构建可持续发展的金融科技创新驱动力，致力于为客户提供交易 、投融资、财富管理等高品质的服务和产品。会上，托管人华鑫证券分享了私募证券基金备案案例，并结合相关法律法规进行了详细解读。华鑫托管秉持“效率高、服务好”的理念，为公募基金、资管计划、证券类私募基金提供覆盖产品“募、投、管、退”全生命流程的托管服务。目前，华鑫托管凭借自主研发的管理人服务平台，实现了产品全生命周期线上化管理，同时结合专业化服务团队，助力私募产品备案，为管理人合规运作保驾护航。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/70/03-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技合伙人朱为从“智能算法赋能投资交易”的角度进行了分享。近年来，非凸科技深入观察行业发展态势，持续产品创新，在性能保证、技术研发、绩效转化等方面均通过了市场和时间的严苛考验，成为了市场上能兼顾拆单算法和底仓增强算法的首选供应商。此外，非凸科技结合市场需求，不断完善智能算法生态，并提供安全、稳定、高效、合规的交易运营服务，可以更好地满足客户交易需求。
                  </p>
                  <p>
                    未来，非凸科技将持续精进算法交易能力，与伙伴们共同打造开放、协作、共赢的智能交易服务生态。也将深度研究政策需求，做好风控合规工作，全力支持业务开展，助力金融行业高质量发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News70",
};
</script>

<style></style>
